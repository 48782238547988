const dialog = {
	namespaced: true,
	state: () => ({
		isActive: false,
		icon: "fa-exclamation-triangle",
		iconType: "has-text-danger",
		message: "Default Message",
		errors: "",
		buttonLeftText: "",
		buttonLeftFunc: () => null,
		buttonRightText: "",
		buttonRightFunc: () => null,
		buttonXText: "",
		buttonXFunc: () => null
	}),
	getters: {
		isActive: (state) => state.isActive,
		icon: (state) => state.icon,
		iconType: (state) => state.iconType,
		message: (state) => state.message,
		errors: (state) => state.errors,
		buttonLeftText: (state) => state.buttonLeftText,
		buttonLeftFunc: (state) => state.buttonLeftFunc,
		buttonRightText: (state) => state.buttonRightText,
		buttonRightFunc: (state) => state.buttonRightFunc,
		buttonXText: (state) => state.buttonXText,
		buttonXFunc: (state) => state.buttonXFunc
	},
	mutations: {
		openDialog: (state) => state.isActive = true,
		closeDialog: (state) => state.isActive = false,
		setIcon: (state, icon) => state.icon = icon,
		setIconType: (state, iconType) => state.iconType = iconType,
		setMessage: (state, message) => state.message = message,
		setErrors: (state, errors) => {
			state.errors = errors;
		},
		setButtonLeftText: (state, text) => state.buttonLeftText = text,
		setButtonLeftFunc: (state, func) => state.buttonLeftFunc = func,
		setButtonRightText: (state, text) => state.buttonRightText = text,
		setButtonRightFunc: (state, func) => state.buttonRightFunc = func,
		setButtonXText: (state, text) => state.buttonXText = text,
		setButtonXFunc: (state, func) => state.buttonXFunc = func
	},
	actions: {
		openDialog: (context) => context.commit('openDialog'),
		closeDialog: (context) => context.commit('closeDialog'),
		setIcon: (context, icon) => context.commit('setIcon', icon),
		setIconType: (context, iconType) => context.commit('setIconType', iconType),
		setMessage: (context, message) => context.commit('setMessage', message),
		setErrors: (context, errors) => context.commit('setErrors', errors),
		setButtonLeftText: (context, text) => context.commit('setButtonLeftText', text),
		setButtonLeftFunc: (context, func) => context.commit('setButtonLeftFunc', func),
		setButtonRightText: (context, text) => context.commit('setButtonRightText', text),
		setButtonRightFunc: (context, func) => context.commit('setButtonRightFunc', func),
		setButtonXText: (context, text) => context.commit('setButtonXText', text),
		setButtonXFunc: (context, func) => context.commit('setButtonXFunc', func)
	}
}

export default dialog
