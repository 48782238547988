import axios from "axios"

const location = {
	namespaced: true,
	state: () => ({
		latitude: 91,
		longitude: 181,
		display_name: '',
		building_number: '',
		street: '',
		city: '',
		county: '',
		state: '',
		postal_code: '',
		country: '',
		country_code: '',
		geolocationPositionError: null,
		reverseGeocodeData: null,
		reverseGeocodeError: null
	}),
	getters: {
		isValidPosition: (state) => (
			state.latitude >= -90 &&
			state.latitude <= 90 &&
			state.longitude >= -180 &&
			state.longitude <= 180),
		getLatitude: (state) => state.latitude,
		getLongitude: (state) => state.longitude,
		getGeolocationPositionErrorMessage: (state) => {
			let errorMessage = ""

			if (!state.geolocationPositionError || !state.geolocationPositionError.code) {
				return ""
			}

			switch(state.geolocationPositionError.code) {
				case null:
					// not an error
					return ""
				case state.geolocationPositionError.PERMISSION_DENIED:
					errorMessage =  "permission denied."
					break
				case state.geolocationPositionError.POSITION_UNAVAILABLE:
					errorMessage =  "position unavailable."
					break
				case state.geolocationPositionError.TIMEOUT:
					errorMessage =  "timeout."
					break
				case 999:
					errorMessage =  "geolocation disabled."
					break
				default:
					errorMessage = "unknown error."
					break
			}

			return "Sorry, unable to find your location. Enable location services in the web browser and operating system settings and try again. Error message: " + errorMessage
		},
		get_display_name: (state) => state.display_name,
		get_building_number: (state) => state.building_number,
		get_street: (state) => state.street,
		get_city: (state) => state.city,
		get_county: (state) => state.county,
		get_state: (state) => state.state,
		get_postal_code: (state) => state.postal_code,
		get_country: (state) => state.country,
		get_country_code: (state) => state.country_code
	},
	mutations: {
		setInvalidPosition: (state) => {
			state.latitude = 91
			state.longitude = 181
		},
		setPosition: (state, {coords: {latitude, longitude}}) => {
			// if the given position is out of range
			if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
				return
			}

			state.latitude = latitude
			state.longitude = longitude
		},
		setGeolocationPositionError: (state, error) => state.geolocationPositionError = error,
		clearGeolocationPositionError: (state) => state.geolocationPositionError = {"code":0,"message":""},
		setReverseGeocodeData: (state, data) => state.reverseGeocodeData = data,
		setReverseGeocodeError: (state, error) => state.reverseGeocodeError = error,
		set_display_name: (state, value) => state.display_name = value,
		set_building_number: (state, value) => state.building_number = value,
		set_street: (state, value) => state.street = value,
		set_city: (state, value) => state.city = value,
		set_county: (state, value) => state.county = value,
		set_state: (state, value) => state.state = value,
		set_postal_code: (state, value) => state.postal_code = value,
		set_country: (state, value) => state.country = value,
		set_country_code: (state, value) => state.country_code = value
	},
	actions: {
		requestPosition: (context) => {
			return new Promise((resolve, reject) => {
				context.commit("clearGeolocationPositionError")

				if (!navigator.geolocation) {
					context.commit("setGeolocationPositionError", {"code":999,"message":"geolocation disabled"})
					reject("geolocation disabled")
				} else {
					navigator.geolocation.getCurrentPosition((position) => {
						context.commit("setPosition", position)
						resolve()
					}, (error) => {
						context.commit("setGeolocationPositionError", error)
						reject(error)
					}, {
						enableHighAccuracy: true,
						timeout: 60000,
						maximumAge: 60000
					})
				}
			}).catch((error) => {
				context.commit("setGeolocationPositionError", error)
			})
		},
		requestReverseGeocode: (context) => {
			return new Promise((resolve, reject) => {
				if (!context.getters.isValidPosition) {
					reject("invalid location")
				} else {
					axios.get(
						`location/v1/lat/${context.getters.getLatitude}/lon/${context.getters.getLongitude}`
					).then(response => {
						if (response.data) {
							context.commit("setReverseGeocodeData", response.data)
							context.commit("set_display_name", response.data.display_name)
							context.commit("set_building_number", response.data.address.house_number)
							context.commit("set_street", response.data.address.road)
							context.commit("set_city", response.data.address.city)
							context.commit("set_county", response.data.address.county)
							context.commit("set_state", response.data.address.state)
							context.commit("set_postal_code", response.data.address.postcode)
							context.commit("set_country", response.data.address.country)
							context.commit("set_country_code", response.data.address.country_code)
							sessionStorage.setItem('updatedLocation', JSON.stringify(response.data.address))

							resolve()
						} else {
							reject("no response data")
						}
					}).catch((error) => {
						context.commit("setReverseGeocodeError", error)
						reject(error)
					})
				}
			}).catch((error) => {
				context.commit("setGeolocationPositionError", error)
			})
		},
		requestLocation: (context) => {
			return new Promise((resolve, reject) => {
				context.dispatch("requestPosition").then(() => {
					context.dispatch("requestReverseGeocode").then(() => {
						resolve()
					}).catch((error) => {
						reject(error)
					})
				}).catch((error) => {
					reject(error)
				})
			})
		},
		clear_city_street: (context) => {
			context.commit("set_city", '')
			context.commit("set_street", '')
		}
	}
}

export default location
