import form_000001 from "@/forms/form_000001"

const form = {
	namespaced: true,
	state: () => ({
		formId: 0,
		isLoaded: false,
		maxPages: 0,
		schema: {}
	}),
	getters: {
		getFormId: () => localStorage.getItem('form.formId'),
		isLoaded: (state) => state.isLoaded,
		schema: (state) => state.schema,
		maxPages: (state) => state.maxPages,
		page: (state) => (pageIndex) => {
			if (!state.isLoaded) {
				return {}
			}
			if (pageIndex < 0 || pageIndex > state.schema.pages.length) {
				return {}
			}

			return state.schema.pages[pageIndex]
		}
	},
	mutations: {
		setFormId: (state, value) => {
			localStorage.setItem('form.formId', value)
		},
		setSchema: (state, schema) => state.schema = schema,
		clearSchema: (state) => state.schema = {},
		setLoaded: (state) => state.isLoaded = true,
		setUnloaded: (state) => state.isLoaded = false,
		setMaxPages: (state, maxPages) => state.maxPages = maxPages
	},
	actions: {
		setFormId: (context, value) => context.commit('setFormId', value),
		loadSchema: (context, value) => {
			if (!context.getters.isLoaded) {
				if (value === "000001") {
					context.commit('setSchema', form_000001)
					context.commit('setMaxPages', form_000001.pages.length)
					context.commit('setLoaded')
				}
			}
		},
		unloadSchema: (context) => {
			if (context.getters.isLoaded) {
				context.commit('clearSchema')
				context.commit('setUnloaded')
			}
		}
	}
}

export default form
