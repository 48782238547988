import axios from "axios"

const session = {
	namespaced: true,
	state: () => ({
	}),
	getters: {
		// getIsExpired: () => {
		// 	let epochTime = Math.floor(new Date().getTime() / 1000.0)
		// 	let expires = localStorage.getItem('session.expires')
		//
		// 	if (expires && epochTime < expires) {
		// 		return false
		// 	}
		//
		// 	return true
		// },
		getIsValid: () => localStorage.getItem('session.isValid'),
		getIsValidUser: () => (obj) => {
			return !(!obj.userId
				|| !obj.randomId
				|| !obj.firstName
				|| !obj.lastName
				|| !obj.expires)
		},
		getIsValidOrganization: () => (obj) => {
			return !(!obj.organizationId
				|| !obj.originatingAgencyIdentifier
				|| !obj.organizationName
				|| !obj.city
				|| !obj.county
				|| !obj.state)
		},
		getId: () => localStorage.getItem('session.id'),
		getUserId: () => localStorage.getItem('session.userId'),
		getRandomId: () => localStorage.getItem('session.randomId'),
		getOrganizationId: () => localStorage.getItem('session.organizationId'),
		getOriginatingAgencyIdentifier: () => localStorage.getItem('session.originatingAgencyIdentifier'),
		getOrganizationName: () => localStorage.getItem('session.organizationName'),
		getCity: () => localStorage.getItem('session.city'),
		getCounty: () => localStorage.getItem('session.county'),
		getState: () => localStorage.getItem('session.state'),
		getFirstName: () => localStorage.getItem('session.firstName'),
		getLastName: () => localStorage.getItem('session.lastName'),
		getExpires: () => localStorage.getItem('session.expires')
	},
	mutations: {
		clearLocalStorage: () => {
			localStorage.removeItem("session.id")
			localStorage.removeItem("session.userId")
			localStorage.removeItem("session.roles")
			localStorage.removeItem("session.randomId")
			localStorage.removeItem("session.organizationId")
			localStorage.removeItem("session.originatingAgencyIdentifier")
			localStorage.removeItem("session.organizationName")
			localStorage.removeItem("session.city")
			localStorage.removeItem("session.county")
			localStorage.removeItem("session.state")
			localStorage.removeItem("session.firstName")
			localStorage.removeItem("session.lastName")
			localStorage.removeItem("session.expires")
			localStorage.removeItem("experience")
			localStorage.removeItem("assignment")
			localStorage.setItem("formRecordList", [])

		},
		clearSchema: (state) => {
			state.schema = {}
		},
		setAllUserList: () => {
			let userId = localStorage.getItem('session.userId')
			let experience = localStorage.getItem('experience')
			let assignment = localStorage.getItem('assignment')

			let formRecordList = localStorage.getItem('formRecordList')
			let allUserRecords = JSON.parse(localStorage.getItem('allUserRecords'))

			if (allUserRecords) {
				let newUserData = []
				let elementPresent = false
				for (let i in allUserRecords) {
					if (allUserRecords[i].userId == userId) {
						allUserRecords[i].formRecordList = JSON.parse(formRecordList)
						allUserRecords[i].experience = experience
						allUserRecords[i].assignment = assignment
						elementPresent = true
					}
				}
				if (elementPresent == false) {
					newUserData.push({
						userId: userId,
						formRecordList: JSON.parse(formRecordList),
						experience,
						assignment
					})
				}
				let newUserRecords = [...allUserRecords, ...newUserData]
				localStorage.setItem('allUserRecords', JSON.stringify(newUserRecords))

			} else {
				let newUserData = []
				newUserData.push({
					userId,
					formRecordList: JSON.parse(formRecordList),
					experience,
					assignment
				})

				localStorage.setItem('allUserRecords', [JSON.stringify(newUserData)])
			}
		},
		setId: (state, value) => {
			localStorage.setItem("session.id", value)
		},
		setUserId: (state, value) => {
			localStorage.setItem("session.userId", value)
		},
		setUserRoles: (state, value) => {
			localStorage.setItem("session.roles", value)
		},
		
		setRandomId: (state, value) => {
			localStorage.setItem("session.randomId", value)
		},
		setOrganizationId: (state, value) => {
			localStorage.setItem("session.organizationId", value)
		},
		setOriginatingAgencyIdentifier: (state, value) => {
			localStorage.setItem("session.originatingAgencyIdentifier", value)
		},
		setOrganizationName: (state, value) => {
			localStorage.setItem("session.organizationName", value)
		},
		setCity: (state, value) => {
			localStorage.setItem("session.city", value)
		},
		setCounty: (state, value) => {
			localStorage.setItem("session.county", value)
		},
		setState: (state, value) => {
			localStorage.setItem("session.state", value)
		},
		setFirstName: (state, value) => {
			localStorage.setItem("session.firstName", value)
		},
		setLastName: (state, value) => {
			localStorage.setItem("session.lastName", value)
		},
		setExpires: (state, value) => {
			localStorage.setItem("session.expires", value)
		},
		setIsValid: (state, value) => {
			localStorage.setItem("session.isValid", value)
		}
	},
	actions: {
		setSession: (context, value) => {
			context.commit("setId", value.id)
			context.commit("setUserId", value.userId)
			context.commit("setUserRoles", value.roles)
			context.commit("setRandomId", value.randomId)
			context.commit("setOrganizationId", value.organizationId)
			context.commit("setOriginatingAgencyIdentifier", value.originatingAgencyIdentifier)
			context.commit("setOrganizationName", value.organizationName)
			context.commit("setCity", value.city)
			context.commit("setCounty", value.county)
			context.commit("setState", value.state)
			context.commit("setFirstName", value.firstName)
			context.commit("setLastName", value.lastName)
			context.commit("setExpires", value.expires)

		},
		clearSchema: (context, value) => context.commit("clearSchema"),

		clearLocalStorage: (context, value) => context.commit("clearLocalStorage", value),
		setAllUserList: (context, value) => context.commit("setAllUserList", value),
		setId: (context, value) => context.commit("setId", value),
		setUserId: (context, value) => context.commit("setUserId", value),
		setUserRoles: (context, value) => context.commit("setUserRoles", value),
		setRandomId: (context, value) => context.commit("setRandomId", value),
		setOrganizationId: (context, value) => context.commit("setOrganizationId", value),
		setOriginatingAgencyIdentifier: (context, value) => context.commit("setOriginatingAgencyIdentifier", value),
		setOrganizationName: (context, value) => context.commit("setOrganizationName", value),
		setCity: (context, value) => context.commit("setCity", value),
		setCounty: (context, value) => context.commit("setCounty", value),
		setState: (context, value) => context.commit("setState", value),
		setFirstName: (context, value) => context.commit("setFirstName", value),
		setLastName: (context, value) => context.commit("setLastName", value),
		setExpires: (context, value) => context.commit("setExpires", value),
		setIsValid: (context, value) => context.commit("setIsValid", value),
		loadSessionById: (context, {sessionId}) => {
			return new Promise((resolve, reject) => {
				if (!sessionId) {
					reject()
				}

				// use the provided session token to create a valid session
				axios.get(
					"common/v1/session/" + sessionId
				).then(response => {
					if (response.data) {
						if (!context.getters["getIsValidUser"](response.data)) {
							reject("Session: Your user account is not configured for this service. Please contact support@lenslock.com.")
							return
						}

						if (!context.getters["getIsValidOrganization"](response.data)) {
							reject("Session: Your organization is not configured for this service. Please contact support@lenslock.com.")
							return
						}

						context.dispatch("setSession", response.data).then()
						context.commit("setIsValid", 1)
						resolve()
						return
					}

					context.commit("setIsValid", 0)
					reject("Session: No response received.")
				}).catch((error) => {
					context.commit("setIsValid", 0)
					if (error.response) {
						reject(error.response)
					} else if (error.request) {
						reject(error.request)
					} else {
						reject(error.message)
					}
				})
			})
		}
		// renew: (context) => {
		// 	return new Promise((resolve, reject) => {
		// 		axios.get( "common/v1/session").then(response => {
		// 			if (response.data && response.data.expires) {
		// 				context.commit("setExpires", response.data.expires)
		// 				resolve()
		// 			} else {
		// 				reject("no response data")
		// 			}
		// 		}).catch((error) => {
		// 			reject(error)
		// 		})
		// 	})
		// }
	}
}

export default session
