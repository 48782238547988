import { createStore } from 'vuex'
import profile from '@/store/modules/profile'
import dialog from '@/store/modules/dialog'
import session from '@/store/modules/session'
import form from '@/store/modules/form'
import form_data from '@/store/modules/form_data'
import location from '@/store/modules/location'
import submission from '@/store/modules/submission'

const debug = process.env.NODE_ENV !== 'production'
const store = createStore({strict: debug})

store.registerModule('profile', profile)
store.registerModule('dialog', dialog)
store.registerModule('session', session)
store.registerModule('form', form)
store.registerModule('form_data', form_data)
store.registerModule('location', location)
store.registerModule('submission', submission)

export default store
