const form_000001 = {
"form_id":"000001",
"name":"Stop Data Survey",
"pages":[
	{"sections":[
		{"label":"Date and Time of Stop",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","id":"date","label":"Date","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"date"},
					{"key":"name","value":"date"},
					{"key":"date","value":""}
				]}
			]},
			{"parts":[
				{"tag":"input","id":"time","label":"Time","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"time"},
					{"key":"name","value":"time"},
					{"key":"time","value":""}
				]}
			]},
			{"parts":[
				{"tag":"input","id":"stop_duration","label":"Stop Duration (max 1440 minutes)","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"number"},
					{"key":"name","value":"stop_duration"},
					{"key":"min","value":"0"},
					{"key":"max","value":"1440"},
					{"key":"pattern","value":"[0-9]*"}
				]}
			]},
			{"parts":[
				{"tag":"input","label":"Stop in response to Call for Service","id":"stop_service_call_yes","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"name","value":"stop_service_call"},
					{"key":"value","value":"Yes [Y]"}
				]},
				{"tag":"input","label":"Stop not in response to a Call for Service","id":"stop_service_call_no","attributes":[
					{"key":"type","value":"radio"},
					{"key":"checked","value":"true"},
					{"key":"name","value":"stop_service_call"},
					{"key":"value","value":"No [N]"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Location",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"Default","id":"default_location_type","attributes":[
					{"key":"type","value":"radio"},
					{"key":"required","value":"true"},
					{"key":"name","value":"location_type"},
					{"key":"checked","value":""},
					{"key":"value","value":"Default []"},
					{"key":"oninput","value":
						"remove_class('address_group','is-hidden');" +
						"add_class('school_group','is-hidden');" +
						"add_class('residence_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Is a K-12 Public School","id":"k12_location_type","attributes":[
					{"key":"type","value":"radio"},
					{"key":"name","value":"location_type"},
					{"key":"value","value":"K-12 Public School [Y]"},
					{"key":"oninput","value":
						"remove_class('address_group','is-hidden');" +
						"remove_class('school_group','is-hidden');" +
						"add_class('residence_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Is a Residence or Non-Street","id":"residence_location_type","attributes":[
					{"key":"type","value":"radio"},
					{"key":"name","value":"location_type"},
					{"key":"value","value":"Residence or Non-Street []"},
					{"key":"oninput","value":
						"add_class('address_group','is-hidden');" +
						"add_class('school_group','is-hidden');" +
						"remove_class('residence_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Out of County","id":"out_of_county_location_type","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"name","value":"out_of_county"},
					{"key":"value","value":"Out of County"}
				]},
				{"tag":"button","content":"UPDATE CURRENT LOCATION","class_name":"has-text-centered","attributes":[
					{"key":"onclick","value":"update_location()"}
				]}
			]},
			{"class_name":"is-hidden","id":"school_group","parts":[
				{"tag":"autocomplete","id":"school","label":"School Name","attributes":[
					{"key":"type","value":"text"},
					{"key":"required","value":"true"},
					{"key":"name","value":"school"},
					{"key":"suggestion-table","value":"STATE/schools/COUNTY"}
				]}
			]},
			{"id":"address_group","parts":[
				{"tag":"input","id":"block_number","label":"Block Number","attributes":[
					{"key":"type","value":"number"},
					{"key":"required","value":"true"},
					{"key":"name","value":"block_number"},
					{"key":"min","value":"0"},
					{"key":"pattern","value":"[0-9]*"},
					{"key":"comment","value":"location/get_building_number"}
				]},
				{"tag":"input","id":"street_name","label":"Street Name","attributes":[
					{"key":"type","value":"text"},
					{"key":"required","value":"true"},
					{"key":"name","value":"street_name"},
					{"key":"subscribe","value":"location/get_street"}
				]}
			]},
			{"class_name":"is-hidden","id":"residence_group","parts":[
				{"tag":"input","id":"intersection","label":"Closest Intersection","attributes":[
					{"key":"type","value":"text"},
					{"key":"name","value":"intersection"}
				]},
				{"tag":"input","id":"highway_exit","label":"(or) Highway and Closest Exit","attributes":[
					{"key":"type","value":"text"},
					{"key":"name","value":"highway_exit"}
				]},
				{"tag":"input","id":"landmark","label":"(or) Road Marker, Landmark, Other","attributes":[
					{"key":"type","value":"text"},
					{"key":"name","value":"landmark"}
				]}
			]},
			{"id":"city_group","parts":[
				// {"tag":"input","id":"city","label":"City","attributes":[
				// 	{"key":"type","value":"text"},
				// 	{"key":"name","value":"city"},
				// 	{"key":"required","value":"true"},
				// 	{"key":"subscribe","value":"location/get_city"}
				// ]}
				{"tag":"autocomplete","id":"city","label":"City","attributes":[
					{"key":"type","value":"text"},
					{"key":"required","value":"true"},
					{"key":"name","value":"city"},
					{"key":"suggestion-table","value":"STATE/cities/COUNTY"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Student",
		"required":"true",
		"groups":[
			{"id":"not_student_group","class_name":"is-hidden","parts":[
				{"tag":"input","label":"Not a Student in a Public School Location","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"is_k12_student_default"},
					{"key":"value","value":"[]"},
					{"key":"checked","value":""},
					{"key":"onload","value":"if_stored_remove_class('','location_type','Default []','not_student_group','is-hidden'); if_stored_remove_class('','location_type','Residence or Non-Street []','not_student_group','is-hidden');"}
				]}
			]},
			{"id":"student_group","class_name":"is-hidden","parts":[
				{"tag":"input","label":"Is a K-12 Public School Student","id":"is_k12_student_yes","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"is_k12_student"},
					{"key":"value","value":"Yes [Y]"},
					{"key":"checked","value":""},
					{"key":"onload","value":"if_stored_remove_class('','location_type','K-12 Public School [Y]','student_group','is-hidden');"}
				]},
				{"tag":"input","label":"Not a K-12 Public School Student","id":"is_k12_student_no","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"is_k12_student"},
					{"key":"value","value":"No [N]"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Perceived Race/Ethnicity",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"Asian","id":"race_ethnicity_asian","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"Asian [1]"}
				]},
				{"tag":"input","label":"Black/African American","id":"race_ethnicity_black","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"Black/African American [2]"}
				]},
				{"tag":"input","label":"Hispanic/Latino/a","id":"race_ethnicity_hispanic","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"Hispanic/Latino(a) [3]"}
				]},
				{"tag":"input","label":"Middle Eastern or South Asian","id":"race_ethnicity_middle_eastern","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"Middle Eastern or South Asian [4]"}
				]},
				{"tag":"input","label":"Native American","id":"race_ethnicity_native_american","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"Native American [5]"}
				]},
				{"tag":"input","label":"Pacific Islander","id":"race_ethnicity_pacific_islander","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"Pacific Islander [6]"}
				]},
				{"tag":"input","label":"White","id":"race_ethnicity_white","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"race_ethnicity"},
					{"key":"value","value":"White [7]"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Perceived Gender",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"Male","id":"gender_male","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"gender"},
					{"key":"value","value":"Male [1]"}
				]},
				{"tag":"input","label":"Female","id":"gender_female","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"gender"},
					{"key":"value","value":"Female [2]"}
				]},
				{"tag":"input","label":"Transgender Man/Boy","id":"gender_transgender_man","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"gender"},
					{"key":"value","value":"Transgender Man/Boy [3]"}
				]},
				{"tag":"input","label":"Transgender Woman/Girl","id":"gender_transgender_woman","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"gender"},
					{"key":"value","value":"Transgender Woman/Girl [4]"}
				]},
				{"tag":"input","label":"Gender Nonconforming","id":"gender_nonconforming","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"gender_nonconforming"},
					{"key":"value","value":"Gender Nonconforming [5]"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Perceived LGBT",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"Yes","id":"lgbt_yes","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"lgbt"},
					{"key":"value","value":"Yes [Y]"},
					{"key":"onload","value":"if (is_stored('people','gender','Transgender Man/Boy [3]') || is_stored('people','gender','Transgender Woman/Girl [4]')) { setTimeout(() => { let lgbtYes = document.getElementById('lgbt_yes'); let lgbtNo = document.getElementById('lgbt_no'); lgbtYes.checked = true; lgbtNo.disabled = true; }, 50); }"}
				]},
				{"tag":"input","label":"No","id":"lgbt_no","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"lgbt"},
					{"key":"value","value":"No [N]"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Perceived Age",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"number"},
					{"key":"store","value":"people"},
					{"key":"name","value":"age"},
					{"key":"min","value":"1"},
					{"key":"max","value":"120"},
					{"key":"pattern","value":"[0-9]*"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Limited/No English Fluency",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"Fluent in English","id":"limited_english_no","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"limited_english"},
					{"key":"value","value":"No [N]"}
				]},
				{"tag":"input","label":"Has Limited or No English Fluency","id":"limited_english_yes","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"limited_english"},
					{"key":"value","value":"Yes [Y]"}
				]}
			]}
		]}
	]},{"sections":[
		{"label":"Perceived/Known Disability",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"None","id":"disability_none","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"oninput","value":"toggle_class('disability_group','is-hidden'); if_stored_toggle_class('people','is_k12_student','Yes [Y]','disability_hyperactive_group','is-hidden'); let b = document.getElementById('disability_none'); let c = b.checked; clear_page_inputs(); b.checked = c;"},
					{"key":"value","value":"None [8]"}
				]}
			]},
			{"id":"disability_group","parts":[
				{"tag":"input","label":"Deafness or Difficulty Hearing","id":"disability_deaf","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Deafness or Difficulty Hearing [1]"}
				]},
				{"tag":"input","label":"Speech Impairment or Limited Use of Language","id":"disability_speech","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Speech Impairment or Limited Use of Language [2]"}
				]},
				{"tag":"input","label":"Blind or Limited Vision","id":"disability_blind","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Blind or Limited Vision [3]"}
				]},
				{"tag":"input","label":"Mental Health Condition","id":"disability_mental","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Mental Health Condition [4]"}
				]},
				{"tag":"input","label":"Intellectual or Developmental Disability, Including Dementia","id":"disability_intellect","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Intellectual or Developmental Disability, Including Dementia [5]"}
				]},
				{"tag":"input","label":"Other Disability","id":"disability_other","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Other Disability [6]"}
				]}
			]},
			{"id":"disability_hyperactive_group","class_name":"is-hidden","parts":[
				{"tag":"input","label":"Disability Related to Hyperactivity or Impulsive Behavior","id":"disability_hyperactive_label","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"disability"},
					{"key":"value","value":"Disability Related to Hyperactivity or Impulsive Behavior [7]"},
					{"key":"onload","value":"if_stored_remove_class('people','is_k12_student','Yes [Y]','disability_hyperactive_group','is-hidden');"}
				]}
			]}
		]}
	]}, {"sections":[
		{"label":"Reason For Stop", "required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"Traffic Violation","id":"stop_reason_traffic","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Traffic Violation [1]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"remove_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Reasonable Suspicion that the Person was Engaged in Criminal Activity","id":"stop_reason_suspicion","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Reasonable Suspicion that the Person was Engaged in Criminal Activity [2]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"remove_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Known to be on Parole/Probation/PRCS/Mandatory Supervision","id":"stop_reason_parole","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Known to be on Parole/Probation/PRCS/Mandatory Supervision [3]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Knowledge of Outstanding Arrest Warrant/Wanted Person","id":"stop_reason_warrant","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Knowledge of Outstanding Arrest Warrant/Wanted Person [4]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Investigation to Determine if Person is Truant","id":"stop_reason_truant","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Investigation to Determine if Person is Truant [5]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Consensual Encounter and Search","id":"stop_reason_consensual","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Consensual Encounter and Search [6]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]}
			]},
			{"id":"student_reason_group","class_name":"is-hidden","parts":[
				{"tag":"input","label":"Possible Conduct Warranting Discipline Under Education Code","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"required","value":"true"},
					{"key":"value","value":"Possible Conduct Warranting Discipline Under Education Code [7]"},
					{"key":"onload","value":"if_stored_remove_class('people','is_k12_student','Yes [Y]','student_reason_group','is-hidden');"},
					{"key":"oninput","value":
						"remove_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"Determine if the Student Violated School Policy","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_reason"},
					{"key":"value","value":"Determine if the Student Violated School Policy [8]"},
					{"key":"oninput","value":
						"add_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
			]},
			{"id":"student_discipline_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"AND","class_name":"text-block bold"},
				{"tag":"input","label":"48900 - Suspension or Expulsion (Select Subsection)","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"student_discipline_section"},
					{"key":"value","value":"48900 - Suspension or Expulsion (Select Subsection) [1]"},
					{"key":"oninput","value":
						"remove_class('student_discipline_group','is-hidden');" +
						"remove_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"48900.2 - Suspension or Expulsion for Sexual Harassment","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"student_discipline_section"},
					{"key":"value","value":"48900.2 - Suspension or Expulsion for Sexual Harassment [2]"},
					{"key":"oninput","value":
						"remove_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"48900.3 - Suspension or Expulsion for Hate Violence","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"student_discipline_section"},
					{"key":"value","value":"48900.3 - Suspension or Expulsion for Hate Violence [3]"},
					{"key":"oninput","value":
						"remove_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"48900.4 - Suspension or Expulsion for Harassment, Threats or Intimidation","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"student_discipline_section"},
					{"key":"value","value":"48900.4 - Suspension or Expulsion for Harassment, Threats or Intimidation [4]"},
					{"key":"oninput","value":
						"remove_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]},
				{"tag":"input","label":"48900.7 - Suspension or Expulsion for Terroristic Threats","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"student_discipline_section"},
					{"key":"value","value":"48900.7 - Suspension or Expulsion for Terroristic Threats [5]"},
					{"key":"oninput","value":
						"remove_class('student_discipline_group','is-hidden');" +
						"add_class('suspension_expulsion_group','is-hidden');" +
						"add_class('traffic_violation_group','is-hidden');" +
						"add_class('reasonable_suspicion_group','is-hidden');"
					}
				]}
			]},
			{"id":"suspension_expulsion_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"AND","class_name":"text-block bold"},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"suspension_code"},
					{"key":"placeholder","value":"48900 Sub Code"},
					{"key":"suggestion-table","value":"STATE/ec_offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 Education Code (required)","class_name":"required"},
			]},
			{"id":"traffic_violation_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"AND","class_name":"text-block bold"},
				{"tag":"input","label":"Moving Violation","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"traffic_violation"},
					{"key":"value","value":"Moving Violation [1]"}
				]},
				{"tag":"input","label":"Equipment Violation","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"traffic_violation"},
					{"key":"value","value":"Equipment Violation [2]"}
				]},
				{"tag":"input","label":"Non-moving Violation, including Registration","attributes":[
					{"key":"type","value":"radio"},
					{"key":"store","value":"people"},
					{"key":"name","value":"traffic_violation"},
					{"key":"value","value":"Non-moving Violation, including Registration [3]"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"traffic_violation_code"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 Offense Code (required)","class_name":"required"},
			]},
			{"id":"reasonable_suspicion_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"AND","class_name":"text-block bold"},
				{"tag":"input","label":"Officer Witnessed Commission of a Crime","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Officer Witnessed Commission of a Crime [1]"}
				]},
				{"tag":"input","label":"Matched Suspect Description","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Matched Suspect Description [2]"}
				]},
				{"tag":"input","label":"Witness or Victim ID of Suspect at the Scene","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Witness or Victim ID of Suspect at the Scene [3]"}
				]},
				{"tag":"input","label":"Carrying Suspicious Object","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Carrying Suspicious Object [4]"}
				]},
				{"tag":"input","label":"Actions Indicative of Casing a Victim or Location","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Actions Indicative of Casing a Victim or Location [5]"}
				]},
				{"tag":"input","label":"Suspected of Acting as Lookout","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Suspected of Acting as Lookout [6]"}
				]},
				{"tag":"input","label":"Actions Indicative of a Drug Transaction","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Actions Indicative of a Drug Transaction [7]"}
				]},
				{"tag":"input","label":"Actions Indicative of Engaging in a Violent Crime","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Actions Indicative of Engaging in a Violent Crime [8]"}
				]},
				{"tag":"input","label":"Other Reasonable Suspicion of a Crime","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion"},
					{"key":"value","value":"Other Reasonable Suspicion of a Crime [9]"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"reasonable_suspicion_code"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 Offense Code (required)","class_name":"required"},
			]},
			{"id":"stop_narrative_group","label":"Reason for Stop Explanation","parts":[
				{"tag":"textarea","id":"stop_narrative","attributes":[
						{"key":"required","value":"true"},
						{"key":"maxlength","value":"250"},
						{"key":"minlength","value":"5"},
						{"key":"store","value":"people"},
						{"key":"name","value":"stop_narrative"},
						{"key":"oninput","value":"update_char_count('stop_narrative','stop_narrative_count',250);"}
					]},
				{"tag":"span","required":"true","content":"Important: Do not include personally identifying information, such as names, DOBs, addresses, ID numbers, etc. (required)"},
				{"tag":"label","id":"stop_narrative_count","content":"250 characters","class_name":"text-block"}
			]}
		]}
	]}, {"sections":[
		{"label":"Actions Taken During Stop",
		"required":"true",
		"groups":[
			{"parts":[
				// toggle_class('contraband_or_evidence_discovered_group','is-hidden');
				{"tag":"input","label":"None","id":"actions_taken_none", "class_name":"checkIfConsensualSelected","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"required","value":"true"},
					{"key":"oninput","value":"toggle_class('actions_taken_group','is-hidden'); if_stored_toggle_class('people','is_k12_student','Yes [Y]','student_actions_group','is-hidden'); add_class('search_basis_group','is-hidden'); add_class('search_basis_explanation_group','is-hidden'); add_class('property_type_seized_group','is-hidden'); add_class('property_seizure_basis_group','is-hidden'); let b = document.getElementById('actions_taken_none'); let c = b.checked; clear_page_inputs(); b.checked = c"},
					{"key":"value","value":"None [\"24,na\"]"}
				]}
			]},
			{"id":"student_actions_group","class_name":"is-hidden","parts":[
				{"tag":"input","label":"Admission or Written Statement Obtained from Student","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Admission or Written Statement Obtained from Student [\"23,na\"]"},
					{"key":"onload","value":"if_stored_remove_class('people','is_k12_student','Yes [Y]','student_actions_group','is-hidden');"}
				]}
			]},
			{"id":"actions_taken_group","parts":[
				{"tag":"input","label":"Person Removed from Vehicle by Order","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Person Removed from Vehicle by Order [\"1,na\"]"}
				]},
				{"tag":"input","label":"Person Removed from Vehicle by Physical Contact","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Person Removed from Vehicle by Physical Contact [\"2,na\"]"}
				]},
				{"tag":"input","label":"Field Sobriety Test Conducted","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Field Sobriety Test Conducted [\"3,na\"]"}
				]},
				{"tag":"input","label":"Curbside Detention","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Curbside Detention [\"4,na\"]"}
				]},
				{"tag":"input","label":"Handcuffed or Flex Cuffed","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Handcuffed or Flex Cuffed [\"5,na\"]"}
				]},
				{"tag":"input","label":"Patrol Car Detention","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Patrol Car Detention [\"6,na\"]"}
				]},
				{"tag":"input","label":"Canine Removed from Vehicle or Used to Search","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Canine Removed from Vehicle or Used to Search [\"7,na\"]"}
				]},
				{"tag":"input","label":"Firearm Pointed at Person","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Firearm Pointed at Person [\"8,na\"]"}
				]},
				{"tag":"input","label":"Firearm Discharged or Used","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Firearm Discharged or Used [\"9,na\"]"}
				]},
				{"tag":"input","label":"Electronic Control Device Used","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Electronic Control Device Used [\"10,na\"]"}
				]},
				{"tag":"input","label":"Impact Projectile Discharged or Used (e.g., Blunt Impact Projectile, Rubber Bullets, or Bean Bag)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Impact Projectile Discharged or Used (e.g., Blunt Impact Projectile, Rubber Bullets, or Bean Bag) [\"11,na\"]"}
				]},
				{"tag":"input","label":"Canine Bit or Held Person","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Canine Bit or Held Person [\"12,na\"]"}
				]},
				{"tag":"input","label":"Baton or Other Impact Weapon Used","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Baton or Other Impact Weapon Used [\"13,na\"]"}
				]},
				{"tag":"input","label":"Chemical Spray Used (e.g., Pepper Spray, Mace, or Other Chemicals)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Chemical Spray Used (e.g., Pepper Spray, Mace, or Other Chemicals) [\"14,na\"]"}
				]},
				{"tag":"input","label":"Other Physical or Vehicle Contact","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Other Physical or Vehicle Contact [\"15,na\"]"}
				]},
				{"tag":"input","label":"Person Photographed","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Person Photographed [\"16,na\"]"}
				]},
				{"tag":"input","label":"Vehicle Impounded","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Vehicle Impounded [\"22,na\"]"}
				]},
				{"tag":"label","content":"SEARCH","class_name":"text-block bold"},
				{"tag":"input","label":"Asked for Consent to Search Person", "id":"actions_taken_search_person_consent", "attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"oninput","value": "if (is_checked('actions_taken_search_person_consent')){show_element('actions_taken_search_person_consent_given', true)}else{hide_element('actions_taken_search_person_consent_given', true)}"},
					{"key":"onload","value": "if (is_checked('actions_taken_search_person_consent')){show_element('actions_taken_search_person_consent_given', true)}else{hide_element('actions_taken_search_person_consent_given', true)}"}
				]},
				{"tag":"input","label":"Consent To Search Person Was Given", "id":"actions_taken_search_person_consent_given", "class_name":"is-hidden", "sub_component":"true", "attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"sub_component","value":"true"},
					{"key":"value","value":"Asked for Consent to Search Person [\"17,Y\"]"},
					{"key":"onload","value":"indent_sub_component('actions_taken_search_person_consent_given')"}
				]},
				{"tag":"input","label":"Search of Person was Conducted","id":"actions_taken_search_person","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Search of Person was Conducted [\"18,na\"]"},
					{"key":"oninput","value": "if (!is_checked('actions_taken_search_property')) { toggle_class('search_basis_group','is-hidden'); toggle_class('search_basis_explanation_group','is-hidden'); }"}
				]},
				{"tag":"input","label":"Asked for Consent to Search Property", "id":"actions_taken_search_property_consent","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"oninput","value": "if (is_checked('actions_taken_search_property_consent')){show_element('actions_taken_search_property_consent_given', true)}else{hide_element('actions_taken_search_property_consent_given', true)}"},
					{"key":"onload","value": "if (is_checked('actions_taken_search_property_consent')){show_element('actions_taken_search_property_consent_given', true)}else{hide_element('actions_taken_search_property_consent_given', true)}"}
				]},
				{"tag":"input","label":"Consent To Search Property Was Given", "id":"actions_taken_search_property_consent_given", "class_name":"is-hidden", "sub_component":"true", "attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"sub_component","value":"true"},
					{"key":"value","value":"Asked for Consent to Search Property [\"19,Y\"]"},
					{"key":"onload","value":"indent_sub_component('actions_taken_search_property_consent_given')"}
				]},
				{"tag":"input","label":"Search of Property was Conducted","id":"actions_taken_search_property","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Search of Property was Conducted [\"20,na\"]"},
					{"key":"oninput","value": "if (!is_checked('actions_taken_search_person')) { toggle_class('search_basis_group','is-hidden'); toggle_class('search_basis_explanation_group','is-hidden');  }"}
				]},
				{"tag":"label","content":"SEIZURE","class_name":"text-block bold"},
				{"tag":"input","label":"Property was Seized","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"actions_taken"},
					{"key":"value","value":"Property was Seized [\"21,na\"]"},
					{"key":"oninput","value": "toggle_class('property_type_seized_group','is-hidden'); toggle_class('property_seizure_basis_group','is-hidden');"}
				]}
			]},
			{"id":"search_basis_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"SEARCH BASIS","class_name":"text-block bold"},
				{"tag":"input","label":"Consent Given","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Consent Given [1]"}
				]},
				{"tag":"input","label":"Officer Safety/Safety of Others","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Officer Safety/Safety of Others [2]"}
				]},
				{"tag":"input","label":"Search Warrant","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Search Warrant [3]"}
				]},
				{"tag":"input","label":"Condition of Parole/Probation/PRCS/Mandatory Supervision","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Condition of Parole/Probation/PRCS/Mandatory Supervision [4]"}
				]},
				{"tag":"input","label":"Suspected Weapons","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Suspected Weapons [5]"}
				]},
				{"tag":"input","label":"Visible Contraband","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Visible Contraband [6]"}
				]},
				{"tag":"input","label":"Odor of Contraband","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Odor of Contraband [7]"}
				]},
				{"tag":"input","label":"Canine Detection","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Canine Detection [8]"}
				]},
				{"tag":"input","label":"Evidence of Crime","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Evidence of Crime [9]"}
				]},
				{"tag":"input","label":"Incident to Arrest","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Incident to Arrest [10]"}
				]},
				{"tag":"input","label":"Exigent Circumstances/Emergency","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Exigent Circumstances/Emergency [11]"}
				]},
				{"tag":"input","label":"Vehicle Inventory","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Vehicle Inventory [12]"}
				]},
				{"tag":"input","label":"Suspected Violation of School Policy","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis"},
					{"key":"value","value":"Suspected Violation of School Policy [13]"}
				]}
			]},
			{"id":"search_basis_explanation_group","label":"Search Basis Explanation","class_name":"is-hidden","parts":[
				{"tag":"textarea","id":"search_basis_explanation","attributes":[
					{"key":"required","value":"true"},
					{"key":"maxlength","value":"250"},
					{"key":"store","value":"people"},
					{"key":"name","value":"search_basis_explanation"},
					{"key":"oninput","value":"update_char_count('search_basis_explanation','search_basis_explanation_count',250);"}
				]},
				{"tag":"span","required":"true","content":"Important: Do not include personally identifying information, such as names, DOBs, addresses, ID numbers, etc. (required)"},
				{"tag":"label","id":"search_basis_explanation_count","content":"250 characters","class_name":"text-block"}
			]},
			{"id":"property_type_seized_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"PROPERTY TYPE SEIZED","class_name":"text-block bold"},
				{"tag":"input","label":"Firearms","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Firearms [1]"}
				]},
				{"tag":"input","label":"Ammunition","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Ammunition [2]"}
				]},
				{"tag":"input","label":"Weapon(s) Other than Firearm","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Weapon(s) Other than Firearm [3]"}
				]},
				{"tag":"input","label":"Drugs/Narcotics","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Drugs/Narcotics [4]"}
				]},
				{"tag":"input","label":"Alcohol","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Alcohol [5]"}
				]},
				{"tag":"input","label":"Money","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Money [6]"}
				]},
				{"tag":"input","label":"Drug Paraphernalia","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Drug Paraphernalia [7]"}
				]},
				{"tag":"input","label":"Suspected Stolen Property","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Suspected Stolen Property [8]"}
				]},
				{"tag":"input","label":"Cell Phone(s) or Electronic Devices","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Cell Phone(s) or Electronic Devices [9]"}
				]},
				{"tag":"input","label":"Vehicle","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Vehicle [10]"}
				]},
				{"tag":"input","label":"Other Contraband or Evidence","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_type_seized"},
					{"key":"value","value":"Other Contraband or Evidence [11]"}
				]}
			]},
			{"id":"property_seizure_basis_group","class_name":"is-hidden","parts":[
				{"tag":"label","content":"PROPERTY SEIZURE BASIS","class_name":"text-block bold"},
				{"tag":"input","label":"Safekeeping as Allowed by Law/Statute","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_seizure_basis"},
					{"key":"value","value":"Safekeeping as Allowed by Law/Statute [1]"}
				]},
				{"tag":"input","label":"Contraband","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_seizure_basis"},
					{"key":"value","value":"Contraband [2]"}
				]},
				{"tag":"input","label":"Evidence","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_seizure_basis"},
					{"key":"value","value":"Evidence [3]"}
				]},
				{"tag":"input","label":"Impound of Vehicle","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_seizure_basis"},
					{"key":"value","value":"Impound of Vehicle [4]"}
				]},
				{"tag":"input","label":"Abandoned Property","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_seizure_basis"},
					{"key":"value","value":"Abandoned Property [5]"}
				]},
				{"tag":"input","label":"Suspected of Violation of School Policy","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"property_seizure_basis"},
					{"key":"value","value":"Suspected of Violation of School Policy [6]"}
				]}
			]},
			{"id":"contraband_or_evidence_discovered_group", "parts":[
				{"tag":"label","content":"CONTRABAND OR EVIDENCE DISCOVERED","class_name":"text-block bold"},
				{"tag":"input","label":"None","attributes":[
					{"key":"required","value":"true"},
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"None [1]"}
				]},
				{"tag":"input","label":"Firearms","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Firearm(s) [2]"}
				]},
				{"tag":"input","label":"Ammunition","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Ammunition [3]"}
				]},
				{"tag":"input","label":"Weapon(s) Other than Firearm","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Weapon(s) other than a firearm [4]"}
				]},
				{"tag":"input","label":"Drugs/Narcotics","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Drugs/narcotics [5]"}
				]},
				{"tag":"input","label":"Alcohol","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Alcohol [6]"}
				]},
				{"tag":"input","label":"Money","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Money [7]"}
				]},
				{"tag":"input","label":"Drug Paraphernalia","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Drug paraphernalia [8]"}
				]},
				{"tag":"input","label":"Suspected Stolen Property","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Suspected stolen property [9]"}
				]},
				{"tag":"input","label":"Cell Phone(s) or Electronic Devices","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Cell phone(s) or electronic device(s) [10]"}
				]},				
				{"tag":"input","label":"Other Contraband or Evidence","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"evidence_or_contraband"},
					{"key":"value","value":"Other contraband or evidence [11]"}
				]}
			]},
		]}
	]}, {"sections":[
		{"label":"Result of Stop",
		"required":"true",
		"groups":[
			{"parts":[
				{"tag":"input","label":"No Action","id":"stop_result_none","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"required","value":"true"},
					{"key":"oninput","value":"toggle_class('stop_result_group','is-hidden');add_class('warning_cjis_codes_group','is-hidden');add_class('citation_cjis_codes_group','is-hidden');add_class('cite_and_release_cjis_codes_group','is-hidden');add_class('arrest_cjis_codes_group','is-hidden'); if_stored_toggle_class('people','is_k12_student','Yes [Y]','student_stop_results_group','is-hidden'); let b = document.getElementById('stop_result_none'); let c = b.checked; clear_page_inputs(); b.checked = c"},
					{"key":"value","value":"No Action [1]"}
				]}
			]},
			{"id":"stop_result_group","parts":[
				{"tag":"input","label":"Warning (Verbal or Written)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Warning (Verbal or Written) [2]"},
					{"key":"oninput","value": "toggle_class('warning_cjis_codes_group','is-hidden');"}
				]},
				{"tag":"input","label":"Citation for Infraction (Use for Local Ordinances Only)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Citation for Infraction (Use for Local Ordinances Only) [3]"},
					{"key":"oninput","value": "toggle_class('citation_cjis_codes_group','is-hidden');"}
				]},
				{"tag":"input","label":"In-field Cite and Release","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"In-field Cite and Release [4]"},
					{"key":"oninput","value": "toggle_class('cite_and_release_cjis_codes_group','is-hidden');"}
				]},
				{"tag":"input","label":"Custodial Arrest Pursuant to Outstanding Warrant","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Custodial Arrest Pursuant to Outstanding Warrant [5]"},
					{"key":"oninput","value": "toggle_class('arrest_cjis_codes_group','is-hidden');"}
				]},
				{"tag":"input","label":"Custodial Arrest Without Warrant","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Custodial Arrest Without Warrant [6]"},
					{"key":"oninput","value": "toggle_class('arrest_cjis_codes_group','is-hidden');"}
				]},
				{"tag":"input","label":"Field Interview Card Completed","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Field Interview Card Completed [7]"}
				]},
				{"tag":"input","label":"Noncriminal Transport or Care-Taking Transport (Including Transport by Officer, Ambulance, or Other Agency)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Noncriminal Transport or Care-Taking Transport (Including Transport by Officer, Ambulance, or Other Agency) [8]"}
				]},
				{"tag":"input","label":"Contacted Parent/Legal Guardian or Other Person Responsible for the Minor","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Contacted Parent/Legal Guardian or Other Person Responsible for the Minor [9]"}
				]},
				{"tag":"input","label":"Psychiatric Hold (W&I Code 5150 or 5585.20)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Psychiatric Hold (W&I Code 5150 or 5585.20) [10]"}
				]},
				{"tag":"input","label":"Contacted U.S. Department of Homeland Security (e.g., ICE or CBP)","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Contacted U.S. Department of Homeland Security (e.g., ICE or CBP) [11]"}
				]}
			]},
			{"id":"student_stop_results_group","class_name":"is-hidden","parts":[
				{"tag":"input","label":"Referral to School Administrator","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Referral to School Administrator [12]"},
					{"key":"onload","value":"if_stored_remove_class('people','is_k12_student','Yes [Y]','student_stop_results_group','is-hidden');"}
				]},
				{"tag":"input","label":"Referral to School Counselor or Other Support Staff","attributes":[
					{"key":"type","value":"checkbox"},
					{"key":"store","value":"people"},
					{"key":"name","value":"stop_result"},
					{"key":"value","value":"Referral to School Counselor or Other Support Staff [13]"},
					{"key":"onload","value":"if_stored_remove_class('people','is_k12_student','Yes [Y]','student_stop_results_group','is-hidden');"}
				]}
			]},
			{"id":"warning_cjis_codes_group","class_name":"is-hidden","parts":[
				{"tag":"autocomplete","label":"Warning CJIS Offense Code(s)","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"warning_cjis_codes_1"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"warning_cjis_codes_2"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"warning_cjis_codes_3"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"warning_cjis_codes_4"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"warning_cjis_codes_5"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 to 5 Offense Codes (required)","class_name":"required"}
			]},
			{"id":"citation_cjis_codes_group","class_name":"is-hidden","parts":[
				{"tag":"autocomplete","label":"Citation CJIS Offense Code(s)","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"citation_cjis_codes_1"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"citation_cjis_codes_2"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"citation_cjis_codes_3"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"citation_cjis_codes_4"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"citation_cjis_codes_5"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 to 5 Offense Codes (required)","class_name":"required"}
			]},
			{"id":"cite_and_release_cjis_codes_group","class_name":"is-hidden","parts":[
				{"tag":"autocomplete","label":"In‐field cite and release CJIS Offense Code(s)","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"cite_and_release_cjis_codes_1"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"cite_and_release_cjis_codes_2"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"cite_and_release_cjis_codes_3"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"cite_and_release_cjis_codes_4"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"cite_and_release_cjis_codes_5"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 to 5 Offense Codes (required)","class_name":"required"}
			]},
			{"id":"arrest_cjis_codes_group","class_name":"is-hidden","parts":[
				{"tag":"autocomplete","label":"Custodial arrest CJIS Offense Code(s)","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"arrest_cjis_codes_1"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"arrest_cjis_codes_2"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"arrest_cjis_codes_3"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"arrest_cjis_codes_4"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"autocomplete","attributes":[
					{"key":"type","value":"text"},
					{"key":"store","value":"people"},
					{"key":"name","value":"arrest_cjis_codes_5"},
					{"key":"suggestion-table","value":"STATE/offenses"}
				]},
				{"tag":"label","required":"true","content":"Select 1 to 5 Offense Codes (required)","class_name":"required"}
			]}
		]}
	]}
]}

export default form_000001
