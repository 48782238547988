import axios from "axios"

const submission = {
	namespaced: true,
	state: () => ({
		isActive: false,
		submission: {}
	}),
	getters: {
		getSubmission: (state) => state.submission,
		isActive: (state) => state.isActive,
		getSubmissionTemplate: () => {
			return {
				"id": "",
				"content": {
					"originating_agency_identifier": "",
					"ripa_officer_id": "",
					"assignment": "",
					"experience": "",
					"other_assignment": "",
					"form_data": {
						"date": "",
						"time": "",
						"stop_duration": 0,
						"stop_service_call": false,
						"out_of_county": false,
						"school": "",
						"block_number": "",
						"street_name": "",
						"intersection": "",
						"highway_exit": "",
						"landmark": "",
						"city": "",
						"people": []
					}
				}
			}
		},
		getPersonTemplate: () => {
			return {
				"is_k12_student": false,
				"race_ethnicity": [],
				"gender": "",
				"gender_nonconforming": false,
				"lgbt": false,
				"age": "",
				"limited_english": false,
				"disability": [],
				"stop_reason": "",
				"student_discipline_section": "",
				"suspension_code": "",
				"traffic_violation": "",
				"traffic_violation_code": "",
				"reasonable_suspicion": [],
				"reasonable_suspicion_code": "",
				"stop_narrative": "",
				"actions_taken": [],
				"search_basis": [],
				"search_basis_explanation": "",
				"property_type_seized": [],
				"property_seizure_basis": [],
				"evidence_or_contraband": ["None [1]"],
				"stop_result": [],
				"warning_cjis_codes": "",
				"citation_cjis_codes": "",
				"cite_and_release_cjis_codes": "",
				"arrest_cjis_codes": ""
			}
		}
	},
	mutations: {
		setSubmission: (state, payload) => state.submission = payload,
		openDialog: (state) => state.isActive = true
	},
	actions: {
		create: (context, {formId, originatingAgencyIdentifier, ripaOfficerId, submission, time_to_complete}) => {
			return new Promise((resolve, reject) => {
				if (!formId ||
					!originatingAgencyIdentifier ||
					!ripaOfficerId ||
					!submission
				) {
					reject()
					return
				}

				if (!("content" in submission) ||
					!("form_data" in submission.content) ||
					!("assignment" in submission.content) ||
					!("experience" in submission.content)) {
					reject()
					return
				}

				let otherAssignment = submission.content.other_assignment ? submission.content.other_assignment : null
				axios.post("form/v1/submission", {
					form_id: formId,
					content: {
						originating_agency_identifier: originatingAgencyIdentifier,
						ripa_officer_id: ripaOfficerId,
						assignment: submission.content.assignment,
						experience: submission.content.experience,
						other_assignment: otherAssignment,
						form_data: submission.content.form_data,
						time_to_complete : time_to_complete
					}
				},{
					"headers": {
						"com.lenslock.session": context.rootGetters["session/getId"]
					}
				}).then((response) => {
					resolve(response)
				}).catch((error) => {
					reject(error)
				})
			})
		},
		convert: (context, payload) => {
			return new Promise((resolve) => {
				let s = context.getters.getSubmissionTemplate

				s.content.experience = context.rootGetters["profile/getExperience"]
				s.content.other_assignment = context.rootGetters["profile/getOtherAssignment"]

				switch (context.rootGetters["profile/getAssignment"]) {
					case "1":
						s.content.assignment = "Patrol, Traffic Enforcement, Field Operations [1]"
						break
					case "2":
						s.content.assignment = "Gang Enforcement [2]"
						break
					case "3":
						s.content.assignment = "Compliance Check (E.G. Parole/Probation/PRCS/Mandatory Supervision) [3]"
						break
					case "4":
						s.content.assignment = "Special Events [4]"
						break
					case "5":
						s.content.assignment = "Roadblock or DUI Sobriety Checkpoint [5]"
						break
					case "6":
						s.content.assignment = "Narcotics/Vice [6]"
						break
					case "7":
						s.content.assignment = "Task Force [7]"
						break
					case "8":
						s.content.assignment = "K-12 Public School, Including School Resource Officer or School Police Officer [8]"
						break
					case "9":
						s.content.assignment = "Investigative/Detective [9]"
						break
					case "10":
						s.content.assignment = "Other [10]"
						break
				}

				let f = JSON.parse(
						JSON.stringify(
						{...s.content.form_data, ...payload}))

				if (Array.isArray(f.out_of_county)) {
					if (f.out_of_county.length > 0) {
						f.out_of_county = f.out_of_county[0]
					} else {
						f.out_of_county = ""
					}
				}

				if (Array.isArray(f.stop_service_call)) {
					if (f.stop_service_call.length > 0) {
						f.stop_service_call = f.stop_service_call[0]
					} else {
						f.stop_service_call = ""
					}
				}

				const stringToBoolFormKeys = [
					"out_of_county",
					"stop_service_call"
				]

				stringToBoolFormKeys.forEach((key) => {
					if (typeof(f[key]) === "string") {
						switch (f[key].toLowerCase()) {
							case "yes [y]":
							case "y":
							case "true":
							case "1":
								f[key] = true
								break
							default:
								f[key] = false
								break
						}
					}
				})

				s.content.form_data = f

				// clear any existing people from the template before insertion
				s.content.form_data.people = []

				payload.people.forEach((person) => {
					let p = {...context.getters.getPersonTemplate, ...person}

					// if exists, convert these arrays to their first string value
					const arrayToStringKeys = [
						"gender",
						"gender_nonconforming",
						"is_k12_student",
						"lgbt",
						"limited_english",
						"stop_reason",
						"traffic_violation"
					]

					arrayToStringKeys.forEach((key) => {
						if (Array.isArray(p[key])) {
							if (p[key].length > 0) {
								p[key] = p[key][0]
							} else {
								p[key] = ""
							}
						}
					})

					// if given a string value, convert to bool
					const stringToBoolKeys = [
						"gender_nonconforming",
						"is_k12_student",
						"lgbt",
						"limited_english"
					]

					stringToBoolKeys.forEach((key) => {
						if (typeof(p[key]) === "string") {
							switch (p[key].toLowerCase()) {
								case "yes [y]":
								case "y":
								case "true":
								case "1":
									p[key] = true
									break
								default:
									p[key] = false
									break
							}
						}
					})

					s.content.form_data.people.push(p)
				})

				resolve(JSON.parse(JSON.stringify(s)))
			})
		},
		set: (context, payload) => {
			return new Promise((resolve) => {
				context.commit("setSubmission", payload)
				resolve()
			})
		},
		put: (context, {submissionId, submissionUserId, content}) => {
			return new Promise((resolve, reject) => {
				if (!submissionId || !submissionUserId || !content) {
					reject()
					return
				}

				axios.put("form/v1/submission/" + submissionId, {
					submission_user_id: submissionUserId,
					content: content
				},{
					"headers": {
						"com.lenslock.session": context.rootGetters["session/getId"]
					}
				}).then((response) => {
					resolve(response)
				}).catch((error) => {
					reject(error)
				})
			})
		},
		get: (context, {submissionId, submissionUserId}) => {
			return new Promise((resolve, reject) => {
				if (!submissionId || !submissionUserId) {
					reject()
					return
				}

				// TODO ensure submissionId is a GUID
				// TODO ensure submissionUserId is a GUID

				axios.get("form/v1/submission/"+ submissionId + "?user=" + submissionUserId,{
					"headers": {
						"com.lenslock.session": context.rootGetters["session/getId"]
					}
				}).then((response) => {
					if (response.data) {
						context.commit("setSubmission", response.data[0])
						resolve(response.data[0])
						return
					}

					reject()
				}).catch((error) => {
					if (error.response) {
						reject(error.response)
					} else if (error.request) {
						reject(error.request)
					} else {
						reject(error.message)
					}
				})
			})
		},
		list: (context, {formId}) => {
			return new Promise((resolve, reject) => {
				if (!formId) {
					reject()
					return
				}

				// TODO ensure formId is a GUID

				axios.get("form/v1/submission?form=" + formId,{
					"headers": {
						"com.lenslock.session": context.rootGetters["session/getId"]
					}
				}).then((response) => {
					if (response.data) {
						resolve(response.data)
						return
					}

					reject()
				}).catch((error) => {
					if (error.response) {
						reject(error.response)
					} else if (error.request) {
						reject(error.request)
					} else {
						reject(error.message)
					}
				})
			})
		},
		listForWorkflow: (context, {workflowId}) => {
			return new Promise((resolve, reject) => {
				if (!workflowId) {
					reject()
					return
				}

				// TODO ensure workflowId is a GUID

				axios.get("form/v1/workflow/" + workflowId,{
					"headers": {
						"com.lenslock.session": context.rootGetters["session/getId"]
					}
				}).then((response) => {
					if (response.data) {
						resolve(response.data)
						return
					}

					reject()
				}).catch((error) => {
					if (error.response) {
						reject(error.response)
					} else if (error.request) {
						reject(error.request)
					} else {
						reject(error.message)
					}
				})
			})
		},
		setStatus: (context, {status, submissionList}) => {
			return new Promise((resolve, reject) => {
				// both status and submission list must be passed
				if (!status || !submissionList) {
					reject("Status and SubmissionList are required parameters.")
					return
				}

				// only accepted or rejected status are allowed
				if (status !== 'Accepted' && status !== 'Rejected') {
					reject("Status must be either Accepted or Rejected.")
					return
				}

				// both submission and user are required
				for (let i = 0; i < submissionList.length; ++i) {
					if (!submissionList[i].submissionId && !submissionList[i].userId) {
						reject("Submission ID and User ID are required.")
						return
					}
				}

				axios.put("form/v1/submission", {
					"status": status,
					"submissionList": submissionList
				},{
					"headers": {
						"com.lenslock.session": context.rootGetters["session/getId"]
					}
				}).then(() => {
					resolve()
				}).catch((error) => {
					if (error.response) {
						reject(error.response)
					} else if (error.request) {
						reject(error.request)
					} else {
						reject(error.message)
					}
				})
			})
		}
	}
}

export default submission
