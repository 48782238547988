import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import mitt from '@/mitt'
import '../node_modules/bulma/css/bulma.css'
import '../node_modules/@fortawesome/fontawesome-pro/css/all.css'
import ClickOutside from '@/directives/click-outside'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// we stopped using cookies because third-party cookies were blocked by certain browsers
// axios.defaults.xsrfCookieName = "com.lenslock.session"

// we stopped passing the session as a header because it wasn't available on the initial calls
// it is still passed as a header, but added to each call individually
// axios.defaults.headers.common["com.lenslock.session"] = store.getters["session/getId"]

const app = createApp(App)

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app
	.use(store)
	.use(router)
	.directive("click-outside", ClickOutside)
	.mount('#app')
