const profile = {
	namespaced: true,
	state: () => ({
		// static; represents choices for other assignments
		assignmentOptions: [
			{"id":"0", "name":"Select Your Assignment [0]"},
			{"id":"1", "name":"Patrol, Traffic Enforcement, Field Operations [1]"},
			{"id":"2", "name":"Gang Enforcement [2]"},
			{"id":"3", "name":"Compliance Check (E.G. Parole/Probation/PRCS/Mandatory Supervision) [3]"},
			{"id":"4", "name":"Special Events [4]"},
			{"id":"5", "name":"Roadblock or DUI Sobriety Checkpoint [5]"},
			{"id":"6", "name":"Narcotics/Vice [6]"},
			{"id":"7", "name":"Task Force [7]"},
			{"id":"8", "name":"K-12 Public School, Including School Resource Officer or School Police Officer [8]"},
			{"id":"9", "name":"Investigative/Detective [9]"},
			{"id":"10", "name":"Other [10]"}
		],
		otherAssignmentOption: 10, // static; represents an "Other" assignment selection from assignmentOptions
	}),
	getters: {
		getAssignmentOptions: (state) => state.assignmentOptions,
		getAssignmentName: (state) => state.assignmentOptions[state.assignment].name,
		getAssignment: () => localStorage.getItem("profile.assignment"),
		getExperience: () => localStorage.getItem("profile.experience"),
		getOtherAssignment: () => localStorage.getItem("profile.other_assignment"),
		getUserId: () => localStorage.getItem("profile.user_id"),
		isValid: (state) => {
			let assignment = localStorage.getItem("profile.assignment")
			let experience = localStorage.getItem("profile.experience")
			let otherAssignment = localStorage.getItem("profile.other_assignment")

			return !(!assignment || !experience || (assignment === state.otherAssignmentOption && !otherAssignment))
		},
		getProfileString: (state) => {
			let assignment = localStorage.getItem("profile.assignment")
			let experience = localStorage.getItem("profile.experience")
			let otherAssignment = localStorage.getItem("profile.other_assignment")

			if (!assignment || !experience || (assignment === state.otherAssignmentOption && !otherAssignment)) {
				return "Profile Update Required"
			}

			let pluralExperiences = experience > 1 ? "s" : ""

			if (assignment == state.otherAssignmentOption) {
				return experience + " Year" + pluralExperiences + " " + otherAssignment
			} else {
				return experience + " Year" + pluralExperiences + " "
					+ state.assignmentOptions[assignment].name.replace(/ \[.*\]$/, '')
			}
		}
	},
	mutations: {
		setAssignment: (state, value) => localStorage.setItem("profile.assignment", value),
		setExperience: (state, value) => localStorage.setItem("profile.experience", value),
		setOtherAssignment: (state, value) => localStorage.setItem("profile.other_assignment", value),
		setUserId: (state, value) => localStorage.setItem("profile.user_id", value),
		setUserRoles: (state, value) => localStorage.setItem("profile.roles", value),
		loadProfile: (state, data) => {
			let userId = data.userId
			let collectionJson = localStorage.getItem("profile.collection")

			localStorage.setItem("profile.user_id", userId)

			// cannot load profiles from an empty collection
			if (!collectionJson) {
				return
			}

			let collection = []

			try {
				collection = JSON.parse(collectionJson)

				if (!collection) {
					return
				}
			} catch (e) {
				console.error(e)
				return
			}

			if (!(userId in collection)) {
				return
			}

			if ("profile.assignment" in collection[userId]) {
				localStorage.setItem("profile.assignment", collection[userId]["profile.assignment"])
			}

			if ("profile.experience" in collection[userId]) {
				localStorage.setItem("profile.experience", collection[userId]["profile.experience"])
			}

			if ("profile.other_assignment" in collection[userId]) {
				localStorage.setItem("profile.other_assignment", collection[userId]["profile.other_assignment"])
			}
		},
		saveProfile: () => {
			let assignment = localStorage.getItem("profile.assignment")
			let experience = localStorage.getItem("profile.experience")
			let otherAssignment = localStorage.getItem("profile.other_assignment")
			let userId = localStorage.getItem("profile.user_id")
			let collectionJson = localStorage.getItem("profile.collection")
			let collection = {}

			// load collection
			if (collectionJson) {
				try {
					collection = JSON.parse(collectionJson)
				} catch (e) {
					console.error(e)
				}
			}

			// initialize collection user
			if (!(userId in collection)) {
				collection[userId] = {}
			}

			// assign user data
			collection[userId]["profile.assignment"] = assignment
			collection[userId]["profile.experience"] = experience
			collection[userId]["profile.other_assignment"] = otherAssignment

			// save collection
			try {
				collectionJson = JSON.stringify(collection)

				if (!collectionJson) {
					collectionJson = ""
				}

				localStorage.setItem("profile.collection", collectionJson)
			} catch (e) {
				console.error(e)
			}
		},
		clearProfile: () => {
			localStorage.removeItem("profile.assignment")
			localStorage.removeItem("profile.experience")
			localStorage.removeItem("profile.other_assignment")
			localStorage.removeItem("profile.user_id")
		}
	},
	actions: {
		setAssignment: (context, value) => context.commit("setAssignment", value),
		setExperience: (context, value) => context.commit("setExperience", value),
		setOtherAssignment: (context, value) => context.commit("setOtherAssignment", value),
		loadProfile: (context, data) => context.commit("loadProfile", data),
		clearProfile: (context) => {
			context.commit("saveProfile")
			context.commit("clearProfile")
		}
	}
}

export default profile
