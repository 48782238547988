import form_data_000001 from "@/forms/form_data_000001"

function getStore(state, storeName) {
	let storeObj
	switch (storeName) {
		case ("people"):
			storeObj = state.formRecordList[state.activeFormRecord].people[state.activePersonRecord]
			break
		default:
			storeObj = state.formRecordList[state.activeFormRecord]
			break
	}

	return storeObj
}

const form_data = {
	namespaced: true,
	state: () => ({
		schema: {},
		isLoaded: false,
		formRecordList: [],
		activeFormRecord: 0,
		activePersonRecord: 0
	}),
	getters: {
		getFormData: (state) => state.formRecordList,
		getFormDataItem: (state) => (payload) => {
			let store = getStore(state, payload.store)
			return store[payload.key]
		},
		isLoaded: (state) => state.isLoaded,
		schema: (state) => state.schema,
		getActiveFormRecord: (state) => state.activeFormRecord,
		getActiveFormRecordStatus: (state) => state.formRecordList[state.activeFormRecord].status,
		getActivePersonRecord: (state) => parseInt(state.activePersonRecord) + 1,
		getPersonCount: (state) => state.formRecordList[state.activeFormRecord].people.length,
		getFormRecordList: (state) => state.formRecordList,
		getFormRecord: (state) => state.formRecordList[state.activeFormRecord],
		getLocation: (state) => (index = state.activeFormRecord) => {
			let r = state.formRecordList[index]
			if (r.block_number || r.street_name) {
				return ((r.block_number ? r.block_number + " " : "") + (r.street_name ? r.street_name : "")).trim()
			} else if (r.intersection && r.intersection.trim()) {
				return r.intersection.trim()
			} else if (r.highway_exit && r.highway_exit.trim()) {
				return r.highway_exit.trim()
			} else if (r.landmark && r.landmark.trim()) {
				return r.landmark.trim()
			} else {
				return ""
			}
		},
		getActivePerson: (state) => state.formRecordList[state.activeFormRecord].people[state.activePersonRecord],
	},
	mutations: {
		// manage the loaded form definition
		setSchema: (state, schema) => state.schema = schema,
		clearSchema: (state) => state.schema = {},
		setLoaded: (state) => state.isLoaded = true,
		setUnloaded: (state) => state.isLoaded = false,
		setFormRecord: (state, payload) => {
			state.formRecordList[state.activeFormRecord] = payload
			localStorage.setItem('formRecordList', JSON.stringify(state.formRecordList))
		},
		clearFormRecords: (state) => state.formRecordList = [],
		// TODO actually don't pull records from API in loadFormRecordList, should be a separate call after a login

		// load old form records during app initialization
		loadFormRecordList: (state) => {
			if (typeof(state.formRecordList) === "undefined"
			|| state.formRecordList === null
			|| state.formRecordList.length === 0) {
				if(localStorage.getItem('formRecordList'))
					state.formRecordList = JSON.parse(localStorage.getItem('formRecordList'))
				state.activeFormRecord = localStorage.getItem('activeFormRecord')
				state.activePersonRecord = localStorage.getItem('activePersonRecord')

				if (typeof(state.formRecordList) === "undefined"
				|| state.formRecordList === null
				|| state.formRecordList.length === 0) {
					localStorage.setItem('formRecordList', '[]')
					localStorage.setItem('activeFormRecord', '0')
					localStorage.setItem('activePersonRecord', '0')
					state.formRecordList = []
				}
			}
		},
		// commit a form record list to storage after changes are made
		storeFormRecordList: (state) => localStorage.setItem('formRecordList', JSON.stringify(state.formRecordList)),
		// create a new form record and store it in formRecordList
		createFormRecord: (state) => {
			state.activePersonRecord = 0
			state.activeFormRecord = state.formRecordList.push({
				"status": "Not Saved",
				"people": []
			}) - 1
			state.formRecordList[state.activeFormRecord].index = state.activeFormRecord
		},

		// mark a form record as active for other operations
		setActiveFormRecord: (state, index) => {
			if (index < 0 || index >= state.formRecordList.length) {
				return false
			}
			state.activeFormRecord = index
			localStorage.setItem('activeFormRecord', state.activeFormRecord)
			return true
		},

		// mark a person record as active for other operations
		setActivePersonRecord: (state, index) => {
			if (index < 0 || index >= state.formRecordList[state.activeFormRecord].people.length) {
				return false
			}

			state.activePersonRecord = index
			localStorage.setItem('activePersonRecord', state.activePersonRecord)
			return true
		},
		duplicateFormRecord: (state) => {
			let newForm = JSON.parse(JSON.stringify(state.formRecordList[state.activeFormRecord]))
			newForm.index = state.formRecordList.length
			
			newForm.status = "Not Saved"
			state.formRecordList.push(newForm)
			localStorage.setItem('activeFormRecord', '0')
		},
		// delete a specific record
		deleteFormRecord: (state) => {
			state.formRecordList.splice(state.activeFormRecord, 1)
			localStorage.setItem('activeFormRecord', '0')
		},

		createPersonRecord: (state) => {
			state.activePersonRecord = state.formRecordList[state.activeFormRecord].people.push({
				status: "Not Saved"
			}) - 1
			localStorage.setItem('activePersonRecord', state.activePersonRecord)
		},

		duplicatePersonRecord: (state) => {
			// const newPerson = Object.assign({},state.formRecordList[state.activeFormRecord].people[state.activePersonRecord])
			let newPerson = JSON.parse(JSON.stringify(state.formRecordList[state.activeFormRecord].people[state.activePersonRecord]))

			state.activePersonRecord = state.formRecordList[state.activeFormRecord].people.push(
				newPerson
			) - 1
			localStorage.setItem('activePersonRecord', state.activePersonRecord)
		},

		deletePersonRecord: (state) => {
			let formRecord = state.formRecordList[state.activeFormRecord]
			formRecord.people.splice(state.activePersonRecord, 1)
			localStorage.setItem('activePersonRecord', '0')
		},
		addArrayItem: (state, payload) => {
			let store = getStore(state, payload.store)

			if (typeof(store[payload.key]) === "undefined"
				|| store[payload.key] == null) {
				store[payload.key] = payload.default
			}

			// remove copies before insertion to prevent duplicates
			store[payload.key] = store[payload.key].filter(item => item !== payload.value)
			store[payload.key].push(payload.value)
		},
		removeArrayItem: (state, payload) => {
			let store = getStore(state, payload.store)

			if (typeof(store[payload.key]) === "undefined"
				|| store[payload.key] == null) {
				store[payload.key] = payload.default
			}

			store[payload.key] = store[payload.key].filter(item => item !== payload.value)
		},
		setItemValue: (state, payload) => {
			let store = getStore(state, payload.store)
			store[payload.key] = payload.value
		},
		clearItemValue: (state, payload) => {
			let store = getStore(state, payload.store)
			store[payload.key] = null
		},
		getAllUsers: (state, payload)  => {
			let allUserRecords = JSON.parse(localStorage.getItem('allUserRecords'))

			if (allUserRecords) {
				let newUser = true

				for (let i of allUserRecords) {
					if (payload.userId === i.userId) {
						localStorage.setItem('formRecordList', JSON.stringify(i.formRecordList))
						localStorage.setItem('experience', i.experience)
						localStorage.setItem('assignment', i.assignment)
						newUser = false
					}
				}

				if (newUser) {
					localStorage.setItem('formRecordList', [])
					localStorage.setItem('experience', 0)
					localStorage.setItem('assignment', 0)
				}
			}
		}
	},
	actions: {
		setFormRecord: (context, payload) => context.commit("setFormRecord", payload),
		loadFormRecordList: (context) => context.commit("loadFormRecordList"),
		setActiveFormRecord: (context, index) => context.commit("setActiveFormRecord", index),
		setActivePersonRecord: (context, index) => context.commit("setActivePersonRecord", index),
		createFormRecord: (context) => {
			context.commit("createFormRecord")
			context.commit("createPersonRecord")
			context.commit("storeFormRecordList")
		},
		deleteFormRecord: (context) => {
			context.commit("deleteFormRecord")
			context.commit("storeFormRecordList")
		},
		duplicateFormRecord: (context) => {
			context.commit("duplicateFormRecord")
			context.commit("storeFormRecordList")
		},
		createPersonRecord: (context) => {
			context.commit("createPersonRecord")
			context.commit("storeFormRecordList")
		},
		duplicatePersonRecord: (context) => {
			context.commit("duplicatePersonRecord")
			context.commit("storeFormRecordList")
		},
		deletePersonRecord: (context) => {
			context.commit("deletePersonRecord")

			// if we deleted the last person, add a blank record
			if (context.getters["getPersonCount"] < 1) {
				context.commit("createPersonRecord")
			}

			context.commit("storeFormRecordList")
		},
		// add to a named collection of items
		addArrayItem: (context, payload) => {
			context.commit("addArrayItem", payload)
			context.commit("storeFormRecordList")
		},
		// remove from a named collection of items
		removeArrayItem: (context, payload) => {
			context.commit("removeArrayItem", payload)
			context.commit("storeFormRecordList")
		},
		// modify a single named item
		setItemValue: (context, payload) => {
			context.commit("setItemValue", payload)
			context.commit("storeFormRecordList")
		},
		// clear a single named item
		clearItemValue: (context, payload) => {
			context.commit("clearItemValue", payload)
			context.commit("storeFormRecordList")
		},
		loadSchema: (context, schema) => {
			if (!context.getters.isLoaded) {
				if (schema === "000001") {
					context.commit('setSchema', form_data_000001)
					context.commit('setLoaded')
				}
			}
		},
		unloadSchema: (context) => {
			if (context.getters.isLoaded) {
				context.commit('clearSchema')
				context.commit('setUnloaded')
			}
		},
		getAllUserList: (context, payload) => context.commit('getAllUsers', payload),
		clearFormRecords: (context, payload) => context.commit('clearFormRecords', payload),
		patchFormRecords: (context) => {
			// handle all patching of form records on an as-needed basis
			let formRecordsChanged = false
			let patch_20220401 = localStorage.getItem("patch.20220401")

			// patch.20220401 = mark selected surveys as "Not Saved"
			if (patch_20220401 !== "1") {
				for (let i = 0; i < context.state.formRecordList.length; i++) {
					let date = new Date(context.state.formRecordList[i].date)
					let start = 1640851200
					let end = 1641326400
					let compare = date.getTime() / 1000

					if (compare >= start && compare <= end) {
						if (context.state.formRecordList[i].status === "Saved") {
							context.state.formRecordList[i].status = "Not Saved"
							formRecordsChanged = true
						}
					}
				}

				localStorage.setItem("patch.20220401", "1")
			}

			if (formRecordsChanged) {
				context.commit("storeFormRecordList")
			}
		}
	}
}

export default form_data
