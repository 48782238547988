import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Splash from '../views/Splash.vue'
import store from "@/store"

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Splash',
		component: Splash,
		meta: { requiresSession: false }
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		meta: { requiresSession: false }
	},
	{
		path: '/session/:session_id',
		name: 'Session',
		component: () => import(/* webpackChunkName: "session" */ '../views/Session.vue'),
		meta: { requiresSession: false }
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
		meta: { requiresSession: false }
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
		meta: { requiresSession: true }
	},
	{
		path: '/start',
		name: 'Start',
		component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
		meta: { requiresSession: true }
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
		meta: { requiresSession: true }
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: { requiresSession: true }
	},
	{
		path: '/form/:form_id/page/:page',
		name: 'Form',
		component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue'),
		meta: { requiresSession: true }
	},
	{
		path: '/review',
		name: 'Review',
		component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
		meta: { requiresSession: true }
	},
	{
		path: '/save',
		name: 'Save',
		component: () => import(/* webpackChunkName: "save" */ '../views/Save.vue'),
		meta: { requiresSession: true }
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
		meta: { requiresSession: true }
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeResolve((to, from, next) => {
	const requiresSession = to.matched.some(route => route.meta.requiresSession)

	// TODO for every page request, update expires
	if (!requiresSession || store.getters["session/getIsValid"] == 1) {
		next()
	} else {
		next("/login")
	}
})

export default router
