
import ProgressBar from "@/components/ProgressBar.vue"
import {onMounted} from "vue"
import {useRouter} from "vue-router"

export default {
	name: "Splash",
	components: { ProgressBar },
	setup() {
		const router = useRouter()

		function redirectAfterTimeout(path, timeout) {
			setTimeout(() => {
				router.push(path)
			}, timeout)
		}

		onMounted(() => {
			redirectAfterTimeout("/login", 1000)
		})

		return {}
	}
}
