const form_data_000001 =
{
	"ripa_officer_id": "",
	"date": undefined,
	"time": undefined,
	"stop_duration": null,
	"stop_service_call": [],
	"location_type": "",
	"out_of_county": [],
	"school": "",
	"block_number": 0,
	"street_name": "",
	"intersection": "",
	"highway_exit": "",
	"landmark": "",
	"city": "",
	"beat": "",
	"people": [
	{
		"race_ethnicity": [],
		"gender": "",
		"gender_nonconforming": false,
		"lgbt": false,
		"age": 0,
		"limited_english": false,
		"is_k12_student": false,
		"disability": [],
		"reasonable_suspicion": "",
		"reasonable_suspicion_code": "",
		"stop_reason": "",
		"stop_narrative": "",
		"student_discipline_section": "",
		"suspension_code": "",
		"traffic_violation": "",
		"traffic_violation_code": "",
		"actions_taken": [],
		"search_basis": "",
		"search_basis_explanation": "",
		"evidence_found": [],
		"property_type_seized": "",
		"property_seizure_basis": "",
		"stop_result": [],
		"warning_cjis_codes": [],
		"citation_cjis_codes": [],
		"cite_and_release_cjis_codes": [],
		"arrest_cjis_codes": []
	}]
}

export default form_data_000001
