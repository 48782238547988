
import { mapGetters } from "vuex"
import Dialog from "@/components/Dialog.vue"

export default {
	name: "App",
	components: { Dialog },
	computed: {
		...mapGetters('dialog', ['isActive'])
	}
}
