
import { mapActions, mapGetters } from "vuex";

export default {
	name: "Dialog",
	computed: {
		...mapGetters('dialog', [
			'isActive', 'icon', 'iconType', 'message', 'errors',
			'buttonLeftText', 'buttonLeftFunc',
			'buttonRightText', 'buttonRightFunc',
			'buttonXText', 'buttonXFunc'])
	},
	methods: {
		...mapActions('dialog', ['closeDialog'])
	}
}
