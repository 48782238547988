
import {onMounted} from 'vue'

export default {
	name: "ProgressBar",
	setup(props, context) {
		const type: string = context.attrs.type
		const value: number = context.attrs.value
		const max: number = context.attrs.max
		const ticks: number = context.attrs.ticks
		const timeout: number = context.attrs.timeout

		function updateProgressOnInterval(value = 0, max = 100, ticks = 1000, timeout = 10) {
			let progress = document.querySelector("progress")

			progress.value = value
			progress.max = max

			let interval = setInterval(() => {
				if (ticks === 0) {
					clearInterval(interval)
				} else {
					ticks--
					progress.value += 1
				}
			}, timeout)
		}

		onMounted(() => {
			switch (type) {
				case "interval":
				default:
					updateProgressOnInterval(value, max, ticks, timeout)
					break
			}
		})

		return {}
	}
}
